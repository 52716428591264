/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        WebFont.load({
          typekit: { id: 'ftn6flo' }
        });

        // Collapse navbar when resizing to screen-sm-min and above
        $(window).on('resize', function () {
          if (window.innerWidth > 768) {
            $('#navbar').removeClass('in').find('.sub-menu').hide(0);
          }
        });

        // Sub menu event handler function
        var toggleSubmenu = function(e) {
          e.preventDefault();
          $(this).siblings('.sub-menu').toggle(100);
        };

        // attach event handler when menu is shown
        $('#navbar').on('show.bs.collapse', function() {
          $('.menu-item-has-children > a').on('click', toggleSubmenu);
        });

        // attach event handler when menu is hidden
        $('#navbar').on('hide.bs.collapse', function() {
          $('.menu-item-has-children > a').off('click', toggleSubmenu);
        });

        // Banner video overlay
        $('.banner-video-btn').on('click touchstart', function() {
          $('#video-overlay').fadeIn('slow');
          var main_video = document.getElementById('main-video');

          main_video.play();

          main_video.onended = function() {
            $('#video-overlay').fadeOut('slow', function(){
              var main_video = document.getElementById('main-video');
              main_video.load();
            });
          };

          return false;
        });

        $('a#close').click(function() {
          $('#video-overlay').fadeOut('slow', function(){
            var main_video = document.getElementById('main-video');
            main_video.load();
          });

          return false;
        });

        $(document).keyup(function(e) {
          if(e.keyCode === 27) {
            $('#video-overlay').fadeOut('slow', function(){
              var main_video = document.getElementById('main-video');
              main_video.load();
            });

            return false;
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // // Youtube embed with max-res thumb
        // var youtube = document.querySelectorAll('.youtube-embed');

        // for (var i = 0; i < youtube.length; i++) {
        //   var source =
        //     'https://img.youtube.com/vi/' +
        //     youtube[i].dataset.embed +
        //     '/maxresdefault.jpg';

        //   var image = new Image();
        //   image.src = source;
        //   image.alt = '';
        //   image.addEventListener(
        //     'load',
        //     (function(youtube, i, image) {
        //       youtube[i].firstElementChild.appendChild(image);
        //     })(i)
        //   );

        //   youtube[i].firstElementChild.addEventListener('click', function(e) {
        //     e.preventDefault();

        //     var iframe = document.createElement('iframe');
        //     var parent = this.parentElement;

        //     iframe.setAttribute('frameborder', '0');
        //     iframe.setAttribute('allowfullscreen', '');
        //     iframe.setAttribute('title', parent.dataset.title);
        //     iframe.setAttribute(
        //       'src',
        //       'https://www.youtube-nocookie.com/embed/' +
        //         parent.dataset.embed +
        //         '?rel=0&showinfo=0&autoplay=1&disablekb=1'
        //     );

        //     parent.innerHTML = '';
        //     parent.appendChild(iframe);
        //   });
        // }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
